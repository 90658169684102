import * as Sentry from "@sentry/vue"

export default {
    install(app) {
        Sentry.init({
            app,
            dsn: "https://4e7968713c797dae9e4109fa33de3b10@o4505343821414400.ingest.us.sentry.io/4508957715464192",
            integrations: [Sentry.replayIntegration()],
            replaysSessionSampleRate: 1.0,
            replaysOnErrorSampleRate: 1.0,
        })

        app.config.globalProperties.$sentry = Sentry
    },
}
